.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: 'white;
  font-size: '3em';*/
  z-index: 1000;
  text-shadow: 2px 2px 10px rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Digital-7 Mono', sans-serif;
  font-size: 2rem;
  background-color: rgba(0,0,0,0.5);
  color: #fff;
  padding: 20px;
  border-radius: 10px;
}

.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 5px;
}

label {
  font-size: 0.5rem;
  color: #aaa;
}

.separator {
  display: flex;
  align-items: center;
  margin: 0 5px;
}
